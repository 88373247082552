<template>
    <v-container style="margin: 0; padding: 0">
        <v-row>
            <v-col cols="12">
                <v-subheader class="headline primary--text">PAINEL CONTROLO</v-subheader>
                <p-breadcrumbs page="profile.control-panel.home" />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" sm="12" lg="3" >
                <v-col cols="12">
                    <Profile />
                </v-col>
                
                <v-col cols="12">
                    <Notifications />
                </v-col>
            </v-col>

            <v-col cols="12" sm="12" lg="9">
                <v-col cols="12">
                    <Submissions />
                </v-col>

                <v-col cols="12">
                    <Favorites />
                </v-col>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
import Submissions from '../../../components/author/control-panel/Submissions';
import Favorites from '../../../components/author/control-panel/Favorites';
import Notifications from '../../../components/author/control-panel/Notifications';
import Profile from '../../../components/author/control-panel/Profile';
import { HOME } from '../../../routes';
import { mapActions } from 'vuex';

export default {
    components: {
        Notifications,
        Favorites,
        Submissions,
        Profile
    },

    computed: {
        user() {
            if (this.$user.isAuthenticated)
                return this.$user.info;
            return null;
        },
    },

    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
    },

    mounted() {
        if (this.user != null) {
            this.countSubscriptions({ profile: this.user.id });
            this.favorites({ profile: this.user.id, first: 5 });
            this.lastSubscriptions({ profile: this.user.id, last: 5 })
        }
    },

    methods: {
        ...mapActions('festival/festival', ['countSubscriptions', 'favorites', 'lastSubscriptions'])
    }
}
</script>