<template>
    <div
        class="body p-1x" 
        style="width: 100%; max-width: 100%; heigth: 285px; max-heigth: 285px;"
    >
        <v-list height="285" class="module-background">
            <v-list-item>
                <v-list-item-avatar>
                    <v-btn color="primary" small class="elevation-0"><v-icon color="black">mdi-star</v-icon></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="body-1">Favoritos</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <div v-if="favorites.length > 0">
                <v-list-item
                    v-for="item in favorites"
                    :key="item.title"
                >
                    <v-list-item-icon>
                        <v-icon color="primary">mdi-star</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </div>

            <v-list-item three-line class="fav-card ma-1 mt-10 justify-center align-center" v-else>
                <v-list-item-content>
                    <v-list-item-title class="mb-1 text-center">Sem favoritos de momento</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: mapState('festival/festival', ['favorites'])
}
</script>

<style scoped>
.fav-card {
  border: 1px solid #ECEFF1;
  border-radius: 8px;
}
</style>