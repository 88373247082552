<template>
    <div class="body p-2x" 
        style="width: 100%; max-width: 100%; heigth: 285px; max-heigth: 285px;">
        <v-row>
            <v-col cols="3">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="body-1 text-center">Submissões</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="display-1 text-center primary--text">
                            {{ subscriptionsCount.total }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">Sub.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="display-1 text-center green--text">
                            {{ subscriptionsCount.accepted }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">Sel.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <!--v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="display-1 text-center green--text">
                            {{ subscriptionsCount.pendding }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">Pendente</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item-->
            </v-col>

            <v-col cols="9">
                <v-subheader class="body-1">Submissões recentes</v-subheader>
                <v-data-table
                    :headers="headers"
                    :items="mySubscriptions"
                    :items-per-page="5"
                    class="elevation-0 module-background text-center"
                ></v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            headers: [
                {
                    text: 'Obra',
                    align: 'start',
                    sortable: false,
                    value: 'project',
                },
                { text: 'Festival', value: 'festival' },
                { text: 'Data Submissão', value: 'requestDate' },
            ],
        }
    },

    computed: mapState('festival/festival', ['subscriptionsCount', 'mySubscriptions'])
}
</script>
