<template>
    <div
        class="body pt-1x pr-2x pl-2x pb-3x" 
        style="width: 100%; max-width: 100%; heigth: 285px; max-heigth: 285px;"
    >
        <v-subheader class="body-1">Notificações</v-subheader>
        <v-list-item three-line class="detail-card ma-1" v-if="notitifactions.length > 0">
                <v-list-item-avatar
                    tile
                    size="80"
                >
                    <img
                        :src="logo"
                        alt="PALOP TL"
                    >
                </v-list-item-avatar>
                <v-list-item-content>
                    <div class="overline">FROM</div>
                    <v-list-item-title class="mb-1">Headline 5</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

        <v-list-item three-line class="detail-card ma-1 mt-10 justify-center align-center" v-else>
            <v-list-item-avatar
                tile
                size="80"
            >
                <img
                    :src="logo"
                    alt="PALOP TL"
                >
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="mb-1 text-center">Sem notificações</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>

<script>
export default {
    data() {
        return {
            notitifactions: []
        }
    },

    computed: {
        logo() {
            return '/icon_Palop_TL_notificacao-13.png'
        }
    }
}
</script>

<style scoped>
.detail-card {
  border: 1px solid #ECEFF1;
  border-radius: 8px;
}
</style>