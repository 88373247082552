<template>
    <div
        class="body pt-1x pb-2x pr-3x pl-3x" 
        style="width: 100%; max-width: 100%; heigth: 285px; max-heigth: 285px;"
    >
        <div class="justify-center align-center text-center" v-if="user != null">
            <v-avatar size="200">
                <v-img
                    v-if="avatar"
                    :src="avatar"
                ></v-img>
                <v-icon v-else color="primary" size="200">mdi-account-circle</v-icon>
            </v-avatar>
            <v-card-title class="justify-center align-center">
                <span> {{ user.firstName + ' ' + user.lastName }}</span>
            </v-card-title>

            <v-card-subtitle class="text-center">
                <v-btn v-if="userExist"
                    text 
                    depressed 
                    :to="editProfileRoute"><v-icon small class="mr-2">mdi-cog</v-icon>Editar Perfil</v-btn>
            </v-card-subtitle>
        </div>
    </div>
</template>

<script>
import { PROFILE, profileDashboard } from '../../../routes';
export default {
    data() {
        return  {
            profile: PROFILE
        }
    },
    computed: {
        user() {
            if (this.$user.isAuthenticated) {
                return this.$user.info;
            }
            return null;
        },
        userExist() {
            return !!this.user;
        },
        avatar() {
            return this.$user.info.avatar;
        },
        editProfileRoute() {
            if (!this.userExist) return null
            return profileDashboard.index;
        }
    },
}
</script>